.sidebar {
  position: absolute;
  top: 50px;
  left: -100%;
  height: 100vh;
  width: 80%;
  z-index: 20;
  background-color: rgb(255, 255, 255);
  color: white;
  box-shadow: 3px 8px 20px 2px rgba(0, 0, 0, 0.37);
  transition: 1s ease;
}
.sidenav-lnk {
  li {
    margin-top: 35px;
    list-style: none;
    a {
      text-decoration: none;
      color: black;
    }
  }
}
.sidebar[sidebar="1"] {
  top: 50px;
  left: 0;
}

@media (min-width: 768px) {
  .sidebar {
    display: none;
  }
}
//    @media screen and (max-width: 768px) {
//                 .sidebar {
//                     overflow-y: hidden;

//                 }
//    }

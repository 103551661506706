.news-sec-one:before {
  background-color: #14aae6;
}
.news-sec-one {
  background-color: #dce7ea;
  /*box-shadow: 0px 5px 20px 2px rgba(0, 71, 77, 0.2);*/
}

/*publication style start*/
.pub-head-box {
  background-color: #dfe1f1be;
  //  box-shadow: 0px 5px 100px 5px rgba(77, 77, 97, 0.329);
  //    box-shadow: 0 9px 0px 0px white,
  //    0 -9px 0px 0px white,
  //    12px 0 15px -4px rgba(31, 73, 125, 0.8),
  //    -12px 0 15px -4px rgba(31, 73, 125, 0.8);
}
.pub-head-box:before {
  background-color: #1183bf;
}
/*publication style end*/
/*home style start*/
.PortCard {
  background-color: #d3b563;
  //   height: 32rem;
  padding: 2%;
  overflow: hidden;
}
@media screen and (min-width: 1140px) {
  .PortCard {
    //   display: inline-block;
    //   width: 31%;
    height: 70vh;
  }
  .home-card {
    margin-left: 3.5%;
  }
}
@media screen and (max-width: 992px) {
  .PortCard {
    padding-left: 5%;
  }
  //   .card-row {
  //     display: block;
  //   }

  .home-card {
    margin-left: 0;
  }
}

.neg-merge {
  margin-top: -1.3rem;
}

@media screen and (min-width: 992px) {
  //   .PortCard {
  //     height: 40%;
  //   }
  // .last-p {
  //   margin-bottom: 9rem;
  // }
}

/*home style end*/
/*member card start*/
.char-card-1::after {
  background-color: #625804;
}
.char-card-2 {
  background-color: #dce7ea;
}
.char-card-2::after {
  background-color: #60a9c4;
}
.char-card-3 {
  background-color: #dce7ea;
}
.char-card-3::after {
  background-color: #60a9c4;
}
.char-card-4 {
  background-color: #dce7ea;
}
.char-card-4::after {
  background-color: #60a9c4;
}
.char-card-5 {
  background-color: #dce7ea;
}
.char-card-5::after {
  background-color: #60a9c4;
}
.char-card-gole {
  background-color: #dce7ea;
}
.char-card-gole::after {
  background-color: #1f3b40;
}

/*member card end*/
/*research style start*/
.rsrch-box {
  background-color: #f7edf2;
}
.rsrch-box::before {
  background-color: #d5adad;
}

#rsrch-box_img {
  height: 100%;
  width: 100%;
}

#rsrch-imgBox {
  width: 55%;
  margin: 0.5vw;
  height: 50vh;
  float: right;
  object-fit: center;
}
/*research style start*/

/*footer*/
.home-letest-news {
  margin: 100px 0 50px 200px;
}

@media only screen and (max-width: 750px) {
  .footer-icons {
    // display: flex;
    // justify-content: center;
    // align-items: center;
    text-align: center;
  }
  .upper-ft-cl {
    margin-top: 40px;
    text-align: center;
  }
  .home-letest-news {
    margin: 100px 0 50px 0;
  }
  .support-img {
    margin-top: 100px;
  }
}

/*Footer end*/
@media only screen and (max-width: 750px) {
  .common-col {
    display: flex;
    justify-content: center;
  }
}

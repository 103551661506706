.logo-con {
  background-image: linear-gradient(to right, #f8f8f886, #f8f8f87c);
  height: 30vh;
  max-width: 95%;
  width: 10vh;
  min-width: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 8px solid #5e5b5b44;
  margin: 10px 0 10px 0;
  object-fit: contain;
}
.logo {
  height: 26vh;
}
@media screen and (min-width: 1080px) {
  .super-hline {
    padding-right: 4vw;
  }
}

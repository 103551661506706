@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600&display=swap);
body{margin:0;font-family:-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale}code{font-family:source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace}

body{font-family:"Poppins", sans-serif;overflow-x:hidden}body p{font-size:20px}@media screen and (max-width: 768px){body p{font-size:15px}}.super-hline h1{font-size:3.6vw}.footer{margin-top:2rem}.footer-head{background-image:linear-gradient(#fff, rgba(211,204,227,0.368627))}.dep-chem{color:#f09c01}.footer-bottom{width:100%;height:300px;background-image:url("https://res.cloudinary.com/aditya-college-of-engineering-technology/image/upload/v1622390720/SNU_rdpzqk.png"),linear-gradient(rgba(211,204,227,0.368627), rgba(233,228,240,0.388235));background-blend-mode:overlay;background-size:cover;background-repeat:no-repeat;background-position:center top}@media (min-width: 480px){.upper-ft-cl{margin:10px 0 10px 0;padding:5px}}@media only screen and (max-width: 750px){.fit-mob{width:90%}.fit-mob-center{margin:0 auto}}.profile-img{color:#598d72}.test-color{color:#d48b02;background-image:linear-gradient(rgba(211,204,227,0.823529), rgba(233,228,240,0.827451))}

.dropdown-mnu {
  /* background: rgb(217, 252, 255); */
  width: 200px;
  position: absolute;
  top: 39.45vh;
  list-style: none;
  text-align: start;
  box-shadow: 0px 5px 10px rgba(49, 48, 48, 0.596);
  padding-left: 0px;
}

.dropdown-mnu li {
  width: 100%;
  cursor: pointer;
}

.dropdown-mnu li:hover {
  background: rgb(255, 255, 255);
}

/* .dropdown-mnu.clicked {
    display: none;
} */

.dropdown-link {
  display: block;
  height: 100%;
  width: 100%;
  text-decoration: none;
  color: rgb(255, 255, 255);
  padding: 10px;
}
.dropdown-link:hover {
  color: black;
}

@media screen and (max-width: 960px) {
  /* .fa-caret-down {
        display: none;
    } */
}

.logo-con{background-image:linear-gradient(to right, rgba(248,248,248,0.52549), rgba(248,248,248,0.486275));height:30vh;max-width:95%;width:10vh;min-width:150px;display:-webkit-flex;display:flex;-webkit-justify-content:center;justify-content:center;-webkit-align-items:center;align-items:center;border:8px solid #5e5b5b44;margin:10px 0 10px 0;object-fit:contain}.logo{height:26vh}@media screen and (min-width: 1080px){.super-hline{padding-right:4vw}}

.sidebar{position:absolute;top:50px;left:-100%;height:100vh;width:80%;z-index:20;background-color:#fff;color:white;box-shadow:3px 8px 20px 2px rgba(0,0,0,0.37);transition:1s ease}.sidenav-lnk li{margin-top:35px;list-style:none}.sidenav-lnk li a{text-decoration:none;color:black}.sidebar[sidebar="1"]{top:50px;left:0}@media (min-width: 768px){.sidebar{display:none}}

.news-sec-one:before{background-color:#14aae6}.news-sec-one{background-color:#dce7ea}.pub-head-box{background-color:#dfe1f1be}.pub-head-box:before{background-color:#1183bf}.PortCard{background-color:#d3b563;padding:2%;overflow:hidden}@media screen and (min-width: 1140px){.PortCard{height:70vh}.home-card{margin-left:3.5%}}@media screen and (max-width: 992px){.PortCard{padding-left:5%}.home-card{margin-left:0}}.neg-merge{margin-top:-1.3rem}.char-card-1::after{background-color:#625804}.char-card-2{background-color:#dce7ea}.char-card-2::after{background-color:#60a9c4}.char-card-3{background-color:#dce7ea}.char-card-3::after{background-color:#60a9c4}.char-card-4{background-color:#dce7ea}.char-card-4::after{background-color:#60a9c4}.char-card-5{background-color:#dce7ea}.char-card-5::after{background-color:#60a9c4}.char-card-gole{background-color:#dce7ea}.char-card-gole::after{background-color:#1f3b40}.rsrch-box{background-color:#f7edf2}.rsrch-box::before{background-color:#d5adad}#rsrch-box_img{height:100%;width:100%}#rsrch-imgBox{width:55%;margin:0.5vw;height:50vh;float:right;object-fit:center}.home-letest-news{margin:100px 0 50px 200px}@media only screen and (max-width: 750px){.footer-icons{text-align:center}.upper-ft-cl{margin-top:40px;text-align:center}.home-letest-news{margin:100px 0 50px 0}.support-img{margin-top:100px}}@media only screen and (max-width: 750px){.common-col{display:-webkit-flex;display:flex;-webkit-justify-content:center;justify-content:center}}

.support-card{background-color:#fdf6ee}.support-card::before{background-color:#ff8800}


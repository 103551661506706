.dropdown-mnu {
  /* background: rgb(217, 252, 255); */
  width: 200px;
  position: absolute;
  top: 39.45vh;
  list-style: none;
  text-align: start;
  box-shadow: 0px 5px 10px rgba(49, 48, 48, 0.596);
  padding-left: 0px;
}

.dropdown-mnu li {
  width: 100%;
  cursor: pointer;
}

.dropdown-mnu li:hover {
  background: rgb(255, 255, 255);
}

/* .dropdown-mnu.clicked {
    display: none;
} */

.dropdown-link {
  display: block;
  height: 100%;
  width: 100%;
  text-decoration: none;
  color: rgb(255, 255, 255);
  padding: 10px;
}
.dropdown-link:hover {
  color: black;
}

@media screen and (max-width: 960px) {
  /* .fa-caret-down {
        display: none;
    } */
}
